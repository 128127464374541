<template lang="html">
    <div class="coach">
    
    <msp-app-bar></msp-app-bar>

    <v-container>

<v-card>
      <v-card-title>
      Avviso ai coach
      </v-card-title>
      <v-card-text>
        <p>Attualmente l'app mobile è disponibile solo per gli atleti.</p>
        <p>Puoi utilizzare gli strumenti di CoachPeaking da PC.</p>
      </v-card-text>
    </v-card>
      
    </v-container>
</div>
</template>

<script type="module">
import mspAppBar from "@components/MspAppBar.vue";

const component = {
  mixins: [
  ],
  components: {
    mspAppBar,

  },
        props: [
        ],
        computed: {
        },
        data() {
            return {
            }
        },
        methods: {
        }
    }
    export default component;
</script>

<style lang="scss">
    .msp-component {
    }
</style>
