<template>

    <v-app-bar app dark 
        ref="appbar"
        extension-height=30
        color="grey darken-3">

        <v-btn
            class="btn-home"
            text
            @click="openCalendario" 
        >
            <img id="logo" :src="logo" />
        </v-btn>

        <v-spacer></v-spacer>
        <v-badge :v-if="acquisti"
             :content="acquisti"
             :value="acquisti"
             class="mx-2"
             color="red"
             overlap >
             <v-btn @click.stop="openAcquistiSospesi"
             dark
             small
             fab
             class="mx-2"
             v-if="acquisti"
             :color="(acquisti)?'primary':''" >
             <v-icon
                 dark>
                 mdi-bell
             </v-icon></v-btn>
        </v-badge>
        <v-badge :v-if="countNewCommenti"
                     :content="countNewCommenti"
                     :value="countNewCommenti"
                     :offset-y='"100%"'
                     color="green"
                     class="mr-2"
                     overlap >
                     <v-btn @click="openCommenti"
                     dark
                     small
                     text
                     fab >
                     <v-icon
                         dark>
                         mdi-chat
                     </v-icon></v-btn>
                     </v-badge>
                     <v-badge :v-if="messaggi"
                     :content="messaggi"
                     :value="messaggi"
                     :offset-y='"100%"'
                     color="green"
                     overlap >
                     <v-btn @click="openMessaggi"
                     dark
                     small
                     text
                     fab >
                     <v-icon
                         dark>
                         mdi-email
                     </v-icon></v-btn>
        </v-badge>
        <v-app-bar-nav-icon dark
                            @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <v-navigation-drawer app v-model="drawer" right dark >
            <v-list>
                <template v-for="(item, i) in menu" >
                    <template v-if="!item.group" >
                        <v-list-item :key="i" @click="goTo(item.page)" >
                            <v-list-item-content>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                    <template v-if="item.group" >
                        <v-list-group :key="i" >
                            <template v-slot:activator>
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                                </v-list-item-content> 
                            </template>
                            <v-list-item v-for="(subitem, si) in item.items" :key="i+'-'+si" @click="goTo(subitem.page)" >
                                <v-list-item-title>{{ subitem.title }}</v-list-item-title>
                            </v-list-item>
                        </v-list-group>
                    </template>
                </template>
            </v-list>
            <div class="pa-2">
                <div class="app-version">
                    {{version}}
                </div>
            </div>
            <template v-slot:append>
            </template>

        </v-navigation-drawer>





    <v-dialog
    persistent
      v-model="alertAcquistiSospesi"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
            Acquisti sospesi
        </v-card-title>

        <v-card-text>
        <ul>
            <li>Clicca sull'icona <v-icon>mdi-bell</v-icon> nella barra superiore.</li>
            <li>Seleziona l'acquisto che vuoi applicare.</li>
            <li>Compila i dati necessari per personalizzare il tuo acquisto.</li>
        </ul>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="closeAlertAcquistiSospesi"
          >
            Chiudi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      <template v-if="title" v-slot:extension>
              <v-toolbar-title>{{title}}</v-toolbar-title>
      </template>

    </v-app-bar>
</template>
<script>
import logo from "../../../grafica/coachpeaking-solotesto-w.svg"
import version from "@mixins/version.js"
import env from "@mixins/environment.js"
import menu from "@mixins/menu.js"
import { mapGetters } from 'vuex'

const component = {
  mixins: [
    env,
    menu,
    version,
  ],
  props: {
      title: {
          type: String,
          default: null,
      }
  },
  data() {
      return {
          logo: logo,
          drawer: false,
      }
  },
  computed: {
    ...mapGetters('messaggio', [
      'messaggi',
      'commenti',
      'countNewCommenti',
    ]),
    ...mapGetters('acquisto', [
      'acquisti',
      'alertAcquistiSospesi',
    ]),
    ...mapGetters('utente', [
      'utente',
    ]),
    ...mapGetters('app', [
      'headerHeight',
    ]),
  },
  mounted() {
      this.init();
    this.getHeaderHeight();
    window.addEventListener('resize', this.getHeaderHeight);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getHeaderHeight);
  },
  methods: {
    getHeaderHeight() {
      const appBar = this.$refs.appbar.$el;
      const headerHeight = appBar.offsetHeight;
      this.$store.dispatch("app/setHeaderHeight", headerHeight);
      this.$emit("change-height",this.headerHeight);
    },
      openMessaggi: function () {
          this.$router.push("/chats");
      },

      openCommenti: function () {
          this.$store.dispatch("messaggio/countCommenti");
          this.$router.push("/commenti");
      },

      openAcquistiSospesi: function () {
          this.$router.push("/acquisti/sospesi");
      },

      openCalendario: function () {
          this.$router.push("/calendario");
      },

      closeAlertAcquistiSospesi: function () {
          this.$store.dispatch("acquisto/resetAlertAcquistiSospesi");
      },

    init: function () {
     this.$store.dispatch("messaggio/initCountMessages");
     this.$store.dispatch("messaggio/initCountCommenti");
     this.$store.dispatch("acquisto/initCountPurchases");
    },
  },

}

export default component;
</script>
<style lang="scss">
.btn-home {
    margin: 0;
    padding: 0 !important;
}
.v-toolbar {
    &__content {
        z-index:1;
    }
    &__extension {
        z-index:0;
    }
}

#logo {
  display: block;
  height: auto;
  width: 140px;
}
.v-list-group__items {
  background: var(--col-grigio)
}
.v-app-bar.v-app-bar--fixed {
  z-index: 100;
}
  .app-version {
    padding: 0;
    font-size: 0.8em;
    text-align: right;
    color: #fff;
}
</style>
