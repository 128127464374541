export const menu = {
    atleta: [
        {
            title:"Calendario",
            page: "/calendario"
        },
        {
            title:"Report",
            page: "/report"
        },
        {
            title:"Migliori prestazioni",
            page: "/record"
        },
        {
            title:"Account",
            page: "/account",
            group: true,
            items: [
                {
                    title:"Profilo",
                    page: "/account/profilo"
                },
                {
                    title:"Settimana tipo",
                    page: "/account/settimana-tipo"
                },
                {
                    title:"Connessioni",
                    page: "/account/connessioni"
                },
                {
                    title:"Consensi",
                    page: "/account/consensi"
                },
                {
                    title:"Acquisti",
                    page: "/account/acquisti"
                },
            ],
        },
        {
            title:"Test",
            page: "/test"
        },
        {
            title:"Gare",
            page: "/gare"
        },
        {
            title:"Messaggi",
            page: "/chats"
        },
        {
            title:"Shop",
            page: "/shop"
        },
        {
            title:"Logout",
            page:  {
                path:  "/",
                query: {
                    logout: true
                }
            }
        },
        {
            title:"controlla aggiornamenti",
            page:  {
                path:  "refresh",
            }
        },
    ],
    allenatore: [
        {
            title:"Calendario",
            page: "/calendario"
        },
        {
            title:"News feed",
            page:  "/feedback",
        },
        {
            title:"Account",
            page: "/account",
            group: true,
            items: [
                {
                    title:"Profilo",
                    page: "/account/profilo"
                },
            ],
        },
        {
            title:"Atleta",
            page: "/account",
            group: true,
            items: [
                {
                    title:"Report",
                    page: "/report/"
                },
                {
                    title:"Migliori prestazioni",
                    page: "/record/"
                },
                {
                    title:"Settimana tipo",
                    page: "/account/settimana-tipo"
                },
                {
                    title:"Test",
                    page: "/test"
                },
                // Disattivato perché non si aggiorna l'elenco quando cambi atleta
                //  {
                //      title:"Gare",
                //      page: "/gare"
                //  },
            ],
        },
        {
            title:"Logout",
            page:  {
                path:  "/",
                query: {
                    logout: true
                }
            }
        },
        {
            title:"controlla aggiornamenti",
            page:  {
                path:  "refresh",
            }
        },
    ],
};
