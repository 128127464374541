import {menu} from '../data/menu.js';
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('utente', [
      'utente',
    ]),
  },
    watch: {
        utente: function () {
            this.updateMenu();
        },
    },

    data: function () {
        return {
            menu: menu.allenatore
        }
    },
    mounted() {
        this.updateMenu();
    },
    methods: {
        updateMenu: function () {
            let ruolo = (this.utente && this.utente.is_atleta) ? "atleta" : "allenatore";
            this.menu = menu[ruolo];
        },
        goTo: function (page) {
            if (page.path==='refresh') {
                window.location.reload(true)
            } else {
                this.$router.push(page);
            }
        }
    },
}
